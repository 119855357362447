import React from "react";
var __jsx = React.createElement;
import { useMemo } from 'react';
import styled from 'styled-components';
import { AddressZero } from '@ethersproject/constants';
import { useNativeTokenBalance, useTokenBalance } from "../../state/wallet/hooks";
import { useWeb3React } from '@web3-react/core';
import { useToken } from "../../hooks/useToken";
import { Currency, FixedPointNumber } from "../../utils/fixedPoint";
import { useTokenPrice } from "../../hooks/useTokenPrice";
export var StyledTokenInfo = styled.div.withConfig({
  displayName: "StyledTokenInfo",
  componentId: "sc-1lvs1vi-0"
})(["background:#fafafc;border:1px solid #eff0fa;box-sizing:border-box;border-radius:48px;padding:16px 15px;display:flex;align-items:center;margin-bottom:10px;.logo{width:28px;margin-right:10px;}.symbol{color:#000000;font-size:15px;line-height:24px;display:flex;flex-direction:column;}.info{margin-top:2px;color:#9598ab;font-size:11px;line-height:15px;}.asset{margin-left:auto;display:flex;flex-direction:column;text-align:right;}.balance{color:#5f4dbc;font-weight:500;font-size:15px;line-height:21px;}.value{color:#9598ab;font-weight:500;font-size:11px;line-height:15px;}"]);
export var TokenInfo = function TokenInfo(_ref) {
  var _FixedPointNumber;

  var icon = _ref.icon,
      addr = _ref.addr,
      symbol = _ref.symbol,
      info = _ref.info;
  var token = useToken(addr);

  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account;

  var tokenBalance = useTokenBalance(account, token);
  var ethBalance = useNativeTokenBalance();
  var price = useTokenPrice(addr);
  var balance = useMemo(function () {
    return addr === AddressZero ? ethBalance : tokenBalance;
  }, [tokenBalance, ethBalance, addr]);
  return __jsx(StyledTokenInfo, null, __jsx("img", {
    className: "logo",
    src: icon,
    alt: ""
  }), __jsx("span", {
    className: "symbol"
  }, __jsx("span", null, symbol), info && __jsx("span", {
    className: "info"
  }, info)), __jsx("div", {
    className: "asset"
  }, __jsx("span", {
    className: "balance"
  }, balance === null || balance === void 0 ? void 0 : balance.toDecimalsFixed(4)), __jsx("span", {
    className: "value"
  }, balance ? (_FixedPointNumber = new FixedPointNumber(balance === null || balance === void 0 ? void 0 : balance.times(price), token === null || token === void 0 ? void 0 : token.decimals)) === null || _FixedPointNumber === void 0 ? void 0 : _FixedPointNumber.toCurrency(Currency.USD) : '')));
};