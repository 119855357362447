import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import useSWR from 'swr';
import axios from 'axios';
import { API_ROOT_DOMAIN } from "../constants/rootDomain";
import { useSpotSwapPair } from "../state/dex/hooks";
export var useSpotSwapEstimatedGasLimit = function useSpotSwapEstimatedGasLimit() {
  var _useSpotSwapPair = useSpotSwapPair(),
      _useSpotSwapPair2 = _slicedToArray(_useSpotSwapPair, 2),
      inputToken = _useSpotSwapPair2[0],
      outputToken = _useSpotSwapPair2[1];

  var makerAssetAddress = outputToken === null || outputToken === void 0 ? void 0 : outputToken.address;
  var takerAssetAddress = inputToken === null || inputToken === void 0 ? void 0 : inputToken.address;
  var params = {
    makerAssetAddress: makerAssetAddress,
    takerAssetAddress: takerAssetAddress
  };

  var fetcher = function fetcher(url, params) {
    return axios.get(url, {
      params: params
    }).then(function (res) {
      return res.data;
    });
  };

  var _useSWR = useSWR(["https://api.".concat(API_ROOT_DOMAIN, "/v5/exchange/estimate/gas"), params], fetcher, {
    refreshInterval: 5000
  }),
      data = _useSWR.data,
      error = _useSWR.error;

  return {
    data: data,
    error: error
  };
};