import { isAddress } from 'ethers/lib/utils';
import { parseUnits } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
export var isValidAddress = function isValidAddress(address) {
  return address ? isAddress(address) : false;
}; // @issue: https://imtoken.atlassian.net/browse/R2D2-1324
// Gas Price >= 21000 （单位：Gwei）
// maxPriorityFeeCap >= 21000（单位：Gwei）
// maxFeePerGas 不限制，maxFeePerGas 用不完会退回来

export var gasProtect = function gasProtect(tx) {
  var gasPrice = tx.gasPrice,
      maxPriorityFeePerGas = tx.maxPriorityFeePerGas;
  var limitedGasPrice = parseUnits('21000', 'gwei');
  var limitedMaxPriorityFeePerGas = parseUnits('21000', 'gwei');

  if (gasPrice && BigNumber.from(gasPrice).gt(limitedGasPrice)) {
    throw new Error("Gas Price must be less than ".concat(limitedGasPrice.toNumber(), ", but given ").concat(BigNumber.from(gasPrice).toNumber()));
  }

  if (maxPriorityFeePerGas && BigNumber.from(maxPriorityFeePerGas).gt(limitedMaxPriorityFeePerGas)) {
    throw new Error("maxPriorityFeePerGas must be less than ".concat(limitedMaxPriorityFeePerGas.toNumber(), ", but given ").concat(BigNumber.from(maxPriorityFeePerGas).toNumber()));
  }

  return tx;
};