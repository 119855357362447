import { ArbitrumOne } from "../model/chain/ethereum"; // @notice: See if these numbers need to be updated

export var getBlocksPerFetchForChainId = function getBlocksPerFetchForChainId(chainId) {
  switch (chainId) {
    case ArbitrumOne.chainId:
      return 5;

    default:
      return 1;
  }
};