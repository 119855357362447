import { useLottie } from 'lottie-react';
import animationData from "./data.json";
var defaultLottieOptions = {
  animationData: animationData,
  loop: true,
  autoplay: true
};

var LottieLoading = function LottieLoading(props) {
  var _props$height = props.height,
      height = _props$height === void 0 ? 60 : _props$height;
  var Lotties = useLottie(defaultLottieOptions, {
    height: height
  });
  return Lotties.View;
};

export default LottieLoading;