import { ALL_SUPPORTED_CHAINS, ALL_SUPPORTED_CHAIN_IDS, EXTRA_CONNECT_CHAINS } from "../constants/chain";
export var getChainById = function getChainById(chainId) {
  return ALL_SUPPORTED_CHAINS.find(function (network) {
    return network.chainId === chainId;
  });
};
export var getExtraConnectChainById = function getExtraConnectChainById(chainId) {
  return EXTRA_CONNECT_CHAINS.find(function (network) {
    return network.chainId === chainId;
  });
};
export var getExplorerAddressLink = function getExplorerAddressLink(address, chainId, locale) {
  var _getChainById;

  return ((_getChainById = getChainById(chainId)) === null || _getChainById === void 0 ? void 0 : _getChainById.getExplorerAddressLink(address, locale)) || '';
};
export var getExplorerTransactionLink = function getExplorerTransactionLink(transactionHash, chainId, locale) {
  var _getChainById2;

  return ((_getChainById2 = getChainById(chainId)) === null || _getChainById2 === void 0 ? void 0 : _getChainById2.getExplorerTransactionLink(transactionHash, locale)) || '';
};
export var getExplorerTokenLink = function getExplorerTokenLink(address, chainId, locale) {
  var _getChainById3;

  return ((_getChainById3 = getChainById(chainId)) === null || _getChainById3 === void 0 ? void 0 : _getChainById3.getExplorerTokenLink(address, locale)) || '';
};
export var getChainName = function getChainName(chainId) {
  var _getChainById4, _getExtraConnectChain;

  return ((_getChainById4 = getChainById(chainId)) === null || _getChainById4 === void 0 ? void 0 : _getChainById4.chainName) || ((_getExtraConnectChain = getExtraConnectChainById(chainId)) === null || _getExtraConnectChain === void 0 ? void 0 : _getExtraConnectChain.chainName) || 'Unsupported Chain';
};
export var isTestChain = function isTestChain(chainId) {
  return ALL_SUPPORTED_CHAINS.some(function (network) {
    return network.isTestChain && network.chainId === chainId;
  });
};
export var isLocalChain = function isLocalChain(chainId) {
  return ALL_SUPPORTED_CHAINS.some(function (network) {
    return network.isLocalChain && network.chainId === chainId;
  });
};
export var isSupportedChain = function isSupportedChain(chainId) {
  return ALL_SUPPORTED_CHAIN_IDS.includes(chainId);
};
export var getNetworkSupportedChain = function getNetworkSupportedChain(env) {
  return ALL_SUPPORTED_CHAINS.filter(function (network) {
    return network.matchedEnv.includes(env);
  });
};