var __jsx = React.createElement;
import React from 'react';
import { StyledLoading, StyledLoadingRing } from "./styled";

var Loading = function Loading(props) {
  return __jsx(StyledLoading, {
    style: props.containerStyle || {}
  }, __jsx(StyledLoadingRing, null, __jsx("div", null), __jsx("div", null), __jsx("div", null), __jsx("div", null)));
};

export default Loading;