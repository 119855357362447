import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { updatePendingChainId, updatePoolShares, updateSelectedConnectionType, updateUnstoppableDomainCallbackUri, updateWalletType } from "./actions";
export var initialState = {
  poolShares: {},
  connectWallet: null,
  unstoppableDomainCallbackUri: null,
  selectedConnectionType: null,
  pendingChainId: null
};
export var userReducer = createReducer(initialState, function (builder) {
  return builder.addCase(updatePoolShares, function (state, _ref) {
    var _ref$payload = _ref.payload,
        chainId = _ref$payload.chainId,
        poolShares = _ref$payload.poolShares;
    if (!state.poolShares) state.poolShares = {};
    state.poolShares[chainId] = poolShares;
  }).addCase(updateWalletType, function (state, _ref2) {
    var payload = _ref2.payload;
    state.connectWallet = payload;
  }).addCase(updatePendingChainId, function (state, _ref3) {
    var payload = _ref3.payload;
    state.pendingChainId = payload;
  }).addCase(updateSelectedConnectionType, function (state, _ref4) {
    var payload = _ref4.payload;
    state.selectedConnectionType = payload;
  }).addCase(updateUnstoppableDomainCallbackUri, function (state, _ref5) {
    var payload = _ref5.payload;
    state.unstoppableDomainCallbackUri = payload;
  });
});
export var userPersistConfig = {
  key: 'user',
  storage: storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: []
};
export default persistReducer(userPersistConfig, userReducer);