import { useWeb3React } from '@web3-react/core';
import { useUnstoppedDomainName } from "./useUnstoppedDomainName";
export var useDomainName = function useDomainName() {
  var _useWeb3React = useWeb3React(),
      ENSName = _useWeb3React.ENSName;

  var unstoppableDomainName = useUnstoppedDomainName();
  return [unstoppableDomainName, {
    name: ENSName
  }].find(function (domain) {
    return Boolean(domain.name);
  }) || {
    name: ''
  };
};