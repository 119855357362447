import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _ENS_REGISTRAR_ADDRES, _WETH, _USDC, _ARB, _ETH, _USDT, _LON;

import { ArbitrumOne, ArbitrumRinkeby, Goerli, Mainnet } from "../model/chain/ethereum";
import { AddressZero } from '@ethersproject/constants';
export var ENS_REGISTRAR_ADDRESSES = (_ENS_REGISTRAR_ADDRES = {}, _defineProperty(_ENS_REGISTRAR_ADDRES, Mainnet.chainId, '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'), _defineProperty(_ENS_REGISTRAR_ADDRES, Goerli.chainId, '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'), _ENS_REGISTRAR_ADDRES);
export var WETH = (_WETH = {}, _defineProperty(_WETH, Mainnet.chainId, '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'), _defineProperty(_WETH, Goerli.chainId, '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6'), _defineProperty(_WETH, ArbitrumOne.chainId, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'), _defineProperty(_WETH, ArbitrumRinkeby.chainId, '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9'), _WETH);
export var USDC = (_USDC = {}, _defineProperty(_USDC, ArbitrumOne.chainId, '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'), _defineProperty(_USDC, ArbitrumRinkeby.chainId, '0x8dA45cf09A24B8fc94C522a2e7e09EE3426B3212'), _USDC);
export var ARB = (_ARB = {}, _defineProperty(_ARB, ArbitrumOne.chainId, '0x912CE59144191C1204E64559FE8253a0e49E6548'), _defineProperty(_ARB, Mainnet.chainId, '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1'), _ARB);
export var ETH = (_ETH = {}, _defineProperty(_ETH, Mainnet.chainId, AddressZero), _defineProperty(_ETH, Goerli.chainId, AddressZero), _ETH);
export var USDT = (_USDT = {}, _defineProperty(_USDT, Mainnet.chainId, '0xdac17f958d2ee523a2206206994597c13d831ec7'), _defineProperty(_USDT, Goerli.chainId, '0xa93ef9215b907c19e739e2214e1aa5412a0401b5'), _USDT);
export var LON = (_LON = {}, _defineProperty(_LON, Mainnet.chainId, '0x0000000000095413afC295d19EDeb1Ad7B71c952'), _defineProperty(_LON, Goerli.chainId, '0x6dA0e6ABd44175f50C563cd8b860DD988A7C3433'), _defineProperty(_LON, ArbitrumOne.chainId, '0x55678cd083fcDC2947a0Df635c93C838C89454A3'), _defineProperty(_LON, ArbitrumRinkeby.chainId, '0x3828E007B7413Be83550AB520f706cC1773464DB'), _LON);