import styled from 'styled-components';
export var StyledConnectOptions = styled.div.withConfig({
  displayName: "StyledConnectOptions",
  componentId: "sc-yiretj-0"
})(["background:#fff;.connector-tips{color:#735be9;margin-top:10px;padding:0 2px;}& + &{margin-top:30px;}"]);
export var ConnectLabel = styled.div.withConfig({
  displayName: "ConnectLabel",
  componentId: "sc-yiretj-1"
})(["color:#2c2842;font-size:13px;line-height:11px;margin-bottom:10px;"]);
export var StyledOption = styled.button.withConfig({
  displayName: "StyledOption",
  componentId: "sc-yiretj-2"
})(["background:", ";border:1px solid #eff0fa;box-sizing:border-box;border-radius:50px;font-weight:500;font-size:15px;line-height:16px;color:", ";padding:10px 17px;width:100%;display:flex;align-items:center;cursor:", ";transition:background-color ease-in-out 0.2s;&:hover{background:#f5f7fb;}&:not(:last-of-type){margin-bottom:10px;}img{opacity:", ";}.logo{width:24px;height:24px;margin-right:9px;}.indicator{margin-left:auto;width:16px;height:16px;}.connect-label{color:#9598ab;font-weight:500;font-size:13px;line-height:16px;margin-left:auto;}.option-checked,.option-unchecked{margin-left:8px;}.option-unchecked{border:1px solid #eff0fa;border-radius:50%;width:15px;height:15px;}"], function (_ref) {
  var disabled = _ref.disabled;
  return disabled ? '#FAFAFC' : '#ffffff';
}, function (_ref2) {
  var disabled = _ref2.disabled;
  return disabled ? '#D2D7E5' : '#1A1C1E';
}, function (_ref3) {
  var disabled = _ref3.disabled;
  return disabled ? 'not-allowed' : 'pointer';
}, function (_ref4) {
  var disabled = _ref4.disabled;
  return disabled ? '0.2' : '1';
});