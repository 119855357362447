import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import React from "react";
var __jsx = React.createElement;
import { useNetworkOptions } from "../../../hooks";
import { ConnectLabel, StyledConnectOptions, StyledOption } from "./StyledOptions";
import Checked from "../../../assets/images/checked.svg";
import { ArbitrumOne, ArbitrumRinkeby, Goerli, Mainnet } from "../../../model/chain/ethereum";
import { useTranslation } from 'react-i18next';
import { useSpotSwapRouter } from "../../../hooks";
export var NetworkConnectOptions = function NetworkConnectOptions(_ref) {
  var _NetworkLabel;

  var label = _ref.label,
      onSelect = _ref.onSelect,
      chainId = _ref.chainId;
  var networks = useNetworkOptions();

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var isSpotSwap = useSpotSwapRouter();
  var NetworkLabel = (_NetworkLabel = {}, _defineProperty(_NetworkLabel, Mainnet.chainId, t('network_label_spot')), _defineProperty(_NetworkLabel, Goerli.chainId, t('network_label_spot')), _defineProperty(_NetworkLabel, ArbitrumOne.chainId, t('network_label_limit')), _defineProperty(_NetworkLabel, ArbitrumRinkeby.chainId, t('network_label_limit')), _NetworkLabel);
  var ArbitrumChains = [ArbitrumOne.chainId, ArbitrumRinkeby.chainId];
  return __jsx(StyledConnectOptions, {
    className: "connect-options"
  }, label && __jsx(ConnectLabel, null, " ", label, " "), networks.map(function (chain) {
    return __jsx(StyledOption, {
      disabled: isSpotSwap && ArbitrumChains.includes(chain.chainId),
      key: chain.chainId,
      onClick: function onClick() {
        return onSelect(chain.chainId);
      }
    }, __jsx("img", {
      src: chain.logoUrl,
      alt: chain.chainName,
      className: "logo"
    }), chain.chainName, __jsx("span", {
      className: "connect-label"
    }, NetworkLabel[chain.chainId]), Number(chain.chainId) === Number(chainId) ? __jsx("img", {
      src: Checked,
      alt: "",
      className: "option-checked"
    }) : __jsx("span", {
      className: "option-unchecked"
    }));
  }));
};