import React from "react";
var __jsx = React.createElement;
import ThemeProvider from "../../theme";
import { PersistGate as ReduxPersistProvider } from 'redux-persist/integration/react';
import store, { persistor } from "../../state";
import { Provider as ReduxProvider } from 'react-redux';
import { Web3Provider } from "../Web3Provider";
import { SWRConfig as SWRProvider } from 'swr';
import { WETHProvider } from "../WETH/context";

var GlobalProvider = function GlobalProvider(_ref) {
  var children = _ref.children;
  return __jsx(ThemeProvider, null, __jsx(ReduxProvider, {
    store: store
  }, __jsx(ReduxPersistProvider, {
    loading: null,
    persistor: persistor
  }, __jsx(Web3Provider, null, __jsx(SWRProvider, {
    value: {
      revalidateOnFocus: false
    }
  }, __jsx(WETHProvider, null, children))))));
};

export default GlobalProvider;