import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import axios from 'axios';
import { ASSET_PREFIX } from "../constants/env";
import striptags from 'striptags';
import dayjs from 'dayjs';

var fetcher = function fetcher(url) {
  return axios.get(url).then(function (res) {
    return res.data;
  });
};

export var parseArticle = function parseArticle(article) {
  var _article$body;

  if (!article) return;
  var matches = article === null || article === void 0 || (_article$body = article.body) === null || _article$body === void 0 ? void 0 : _article$body.match(/(?:img[^>]*src=")([^"]*)/);
  article.thumbnail = matches ? matches[1] : "".concat(ASSET_PREFIX, "/images/blog-article-cover.png");
  article.digest = striptags(article === null || article === void 0 ? void 0 : article.body);
  article.created_at = dayjs(article.created_at).format('YYYY-MM-DD');
  article.updated_at = dayjs(article.updated_at).format('YYYY-MM-DD');
  return article;
};
export var useNews = function useNews() {
  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var lang = i18n.resolvedLanguage === 'zh' ? 'zh-cn' : 'en-us'; // 数据源调整为公告中心

  var _useSWR = useSWR("https://tokenlon.zendesk.com/api/v2/help_center/".concat(lang, "/categories/360002021512/articles.json?sort_by=position"), fetcher),
      data = _useSWR.data,
      error = _useSWR.error;

  if (error) {
    console.error(error);
    return [];
  }

  if (!data) return [];
  var news = data.articles.map(parseArticle);
  return news.splice(0, 3);
};