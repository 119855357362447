import { useState } from 'react';

/**
 * 状态切换 Custom Hook
 * @param initial [boolean] 初始状态
 */
export var useSwitch = function useSwitch(initial) {
  var _useState = useState(initial),
      sw = _useState[0],
      setSw = _useState[1];

  var on = function on() {
    return setSw(true);
  };

  var off = function off() {
    return setSw(false);
  };

  var toggle = function toggle() {
    return setSw(!sw);
  };

  return {
    sw: sw,
    toggle: toggle,
    on: on,
    off: off
  };
};