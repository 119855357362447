import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { networkConnection } from "../../connection";
import { useIsWindowVisible } from "../../hooks/useIsWindowVisible";
import useUpdaterByBlock from "../../hooks/useUpdaterByBlock";
import { useAppDispatch } from "../hooks";
import { updateBlockNumber, updateChainId, updateFeeData } from "./reducer";
import { getBlocksPerFetchForChainId } from "../../utils/getBlocksPerFetchForChainId";
export var Updater = function Updater() {
  useNetworkConnectorUpdater();
  useFeeDataUpdater();
  useBlockNumberUpdater();
  var dispatch = useAppDispatch();

  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  useEffect(function () {
    dispatch(updateChainId({
      chainId: chainId
    }));
  }, [dispatch, chainId]);
  return null;
};
export var useBlockNumberUpdater = function useBlockNumberUpdater() {
  var windowVisible = useIsWindowVisible();
  var dispatch = useAppDispatch();

  var _useWeb3React2 = useWeb3React(),
      activeChainId = _useWeb3React2.chainId,
      provider = _useWeb3React2.provider;

  var _useState = useState({
    chainId: activeChainId
  }),
      blockNumber = _useState[0].blockNumber,
      setChainBlock = _useState[1];

  var onBlock = useCallback(function (block) {
    setChainBlock(function (chainBlock) {
      if (chainBlock.chainId === activeChainId || !chainBlock.chainId) {
        var newChainBlock = {
          blockNumber: Math.max(chainBlock.blockNumber || 0, block),
          chainId: activeChainId
        };
        dispatch(updateBlockNumber(newChainBlock));
        return newChainBlock;
      }

      dispatch(updateBlockNumber(chainBlock));
      return chainBlock;
    });
  }, [activeChainId, setChainBlock]);
  useEffect(function () {
    var stale = false;

    if (provider && activeChainId && windowVisible) {
      // reset
      setChainBlock(function (chainBlock) {
        return chainBlock.chainId === activeChainId ? chainBlock : {
          chainId: activeChainId
        };
      });
      provider.getBlockNumber().then(function (block) {
        if (!stale) onBlock(block);
      })["catch"](function (error) {
        console.error("Failed to get block number for chainId: ".concat(activeChainId), error);
      });
      provider.on('block', onBlock);
      return function () {
        stale = true;
        provider.removeListener('block', onBlock);
      };
    }
  }, [activeChainId, onBlock, windowVisible, provider]);
  return blockNumber;
};
export var useFeeDataUpdater = function useFeeDataUpdater() {
  var _useWeb3React3 = useWeb3React(),
      provider = _useWeb3React3.provider,
      chainId = _useWeb3React3.chainId;

  var windowVisible = useIsWindowVisible();
  var dispatch = useAppDispatch();
  var blocksPerFetch = useMemo(function () {
    return getBlocksPerFetchForChainId(chainId);
  }, [chainId]);
  var feeDataCallback = useCallback(function () {
    if (provider && windowVisible) {
      provider.getFeeData().then(function (data) {
        dispatch(updateFeeData({
          chainId: chainId,
          feeData: {
            gasPrice: data.gasPrice.toString(),
            maxFeePerGas: data.maxFeePerGas.toString(),
            maxPriorityFeePerGas: data.maxPriorityFeePerGas.toString()
          }
        }));
      })["catch"](function (err) {
        console.error(err);
      });
    }
  }, [provider, chainId, windowVisible]);
  useUpdaterByBlock(feeDataCallback, blocksPerFetch);
};
export var useNetworkConnectorUpdater = function useNetworkConnectorUpdater() {
  var _useWeb3React4 = useWeb3React(),
      chainId = _useWeb3React4.chainId,
      connector = _useWeb3React4.connector;

  useEffect(function () {
    if (chainId && connector !== networkConnection.connector) {
      networkConnection.connector.activate(chainId);
    }
  }, [chainId, connector]);
};
export default Updater;