import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useModalOpened, useModalState, useModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { ReactComponent as IconQuestion } from "../../assets/images/questionMark.svg";
import { Modal } from "../Modal";
import Portal from "../Portal";
import TokenLogo from "../TokenLogo";
import { LOCALES } from "../../locales";
import { ApproveStatusLabel } from "./ApproveStatusLabel";
import { ApprovalState } from "../../hooks/useApproval";
import { useEffect } from 'react';
import { lockBody } from "../../utils/lockBody";
export var ApprovalModal = function ApprovalModal() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var isOpen = useModalOpened(ApplicationModal.APPROVAL_STATUS);
  var toggleApprovalModal = useModalToggle(ApplicationModal.APPROVAL_STATUS);

  var _useModalState = useModalState(ApplicationModal.APPROVAL_STATUS),
      token = _useModalState.token,
      approvalState = _useModalState.approvalState;

  var onApprovalDismiss = function onApprovalDismiss() {
    toggleApprovalModal();
  };

  var showNotice = function showNotice() {
    var noticeUrl = "https://support.tokenlon.im/hc/".concat(i18n.resolvedLanguage === LOCALES.ZH ? 'zh-cn' : 'en-us', "/articles/360037362332");
    window.open(noticeUrl, '_blank');
  };

  var i18nKey = approvalState === ApprovalState.APPROVED ? 'unApprove_token_to_tokenlon' : 'approve_token_to_tokenlon';
  useEffect(function () {
    lockBody(isOpen);
  }, [isOpen]);
  return __jsx(Portal, null, isOpen && __jsx(Modal.Overlay, {
    onDismiss: onApprovalDismiss
  }, __jsx(StyledApprovalContent, null, __jsx("div", {
    className: "token-info"
  }, __jsx(StyledIconClose, {
    light: true,
    onDismiss: onApprovalDismiss
  }), __jsx("h2", null, t('token_approval')), __jsx(TokenLogo, {
    logo: token === null || token === void 0 ? void 0 : token.logo,
    symbol: token === null || token === void 0 ? void 0 : token.symbol,
    size: 50
  }), __jsx("p", null, t(i18nKey, {
    symbol: token === null || token === void 0 ? void 0 : token.symbol
  }), __jsx(StyledQuestionIcon, {
    onClick: showNotice
  }))), __jsx(ApproveStatusLabel, null))));
};
var StyledApprovalContent = styled(Modal.Content).withConfig({
  displayName: "StyledApprovalContent",
  componentId: "sc-1ed1f0r-0"
})(["display:flex;align-items:flex-start;flex-direction:column;background:rgb(38,44,75);width:360px;padding:0;.token-info{padding-left:25px;padding-top:30px;margin-bottom:18px;}h2{color:white;font-size:18px;font-weight:600;line-height:17px;margin-bottom:20px;}p{display:flex;align-items:center;margin-top:15px;margin-bottom:0px;color:#fff;font-size:12px;line-height:14px;}"]);
var StyledIconClose = styled(Modal.Close).withConfig({
  displayName: "StyledIconClose",
  componentId: "sc-1ed1f0r-1"
})(["position:absolute;right:18px;top:18px;height:12px;width:12px;cursor:pointer;&::after{content:'';width:12px;height:12px;}"]);
var StyledQuestionIcon = styled(IconQuestion).withConfig({
  displayName: "StyledQuestionIcon",
  componentId: "sc-1ed1f0r-2"
})(["border-radius:50%;margin-left:6px;cursor:pointer;"]);