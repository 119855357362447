import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from 'styled-components';
export var Page = styled.div.withConfig({
  displayName: "Page",
  componentId: "sc-ljevcp-0"
})(["display:flex;flex-direction:column;align-items:stretch;justify-content:flex-start;min-height:100vh;background:#f6f8fb;", ""], function (_ref) {
  var theme = _ref.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    background: linear-gradient(179.78deg, #3F366D 19.39%, #6F5294 115.94%);\n  "])));
});