import TokenWebView from '@consenlabs-fe/webview';
import * as Sentry from '@sentry/nextjs';
import { ENV, RUNTIME_ENV } from "./constants/env";
var DSN = 'https://cee4c9568a83464db25543f6ee761193@sentry.qianbao.io/69'; // 非 Error 级别的、可忽略的异常

var ignoreErrors = [
/**
 * 浏览器级别的问题，影响性能但不影响业务
 * safe ignore for reason: This error means that ResizeObserver was not able to deliver all
 * observations within a single animation frame. It is benign (your site will not break)
 *
 * @issue: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
 * @issue: https://github.com/DevExpress/testcafe/issues/4857#issuecomment-598775956
 */
'ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.',
/**
 * 用户操作行为，非 Error
 * ignore this exception from imToken App, which will be thrown when user launch the dapp that has canceled address authorization.
 *
 * @issue: https://imtoken.atlassian.net/browse/R2D2-542
 */
'user_canceled',
/**
 * 环境注入的异常
 *
 * 比如 imToken 在用户拒绝授权时抛出的 global error，以及 imKey 连接失败时抛出的 global error
 *
 * @fixme: 这里暂时处理成忽略，后续需要改为上报异常
 * @issue: https://imtoken.atlassian.net/browse/R2D2-525
 */
'Non-Error promise rejection captured with keys: message']; // @TODO: @izayl remove this file and migrate use next.config.js

export var initSentry = function initSentry() {
  var envImToken = TokenWebView.isTokenEnv(); // Use Sentry at Production and Staging ENV only.

  if (ENV.development || ENV.local) return;
  Sentry.init({
    dsn: DSN,
    release: process.env.release,
    environment: RUNTIME_ENV,
    ignoreErrors: ignoreErrors,
    autoSessionTracking: false
  }); // For DApp container Tag, only mark imToken and other browser container.

  Sentry.setTag('container', envImToken ? 'imToken' : 'browser');

  if (envImToken) {
    Sentry.setTag('imTokenVersion', TokenWebView.getVersion());
  }

  console.log('Sentry initialized');
};
initSentry();