import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _DEFAULT_RPC_PROVIDER;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { ArbitrumOne, ArbitrumRinkeby, Goerli, Mainnet, BSC, Avalanche, Polygon, Cronos, Fantom, Optimism, Klaytn, Moonbeam, Gnosis, Aurora, WCMainnet, WCGoerli, WCArbitrumOne, WCArbitrumRinkeby } from "../model/chain/ethereum";
export var ALL_SUPPORTED_CHAINS = [Mainnet, Goerli, ArbitrumOne, ArbitrumRinkeby];
export var ALL_SUPPORTED_WC_CHAINS = [WCMainnet, WCGoerli, WCArbitrumOne, WCArbitrumRinkeby];
export var EXTRA_CONNECT_CHAINS = [BSC, Avalanche, Polygon, Cronos, Fantom, Optimism, Klaytn, Moonbeam, Gnosis, Aurora];
export var ALL_SUPPORTED_CHAIN_IDS = ALL_SUPPORTED_CHAINS.map(function (chain) {
  return chain.chainId;
});
export var EXTRA_CONNECT_CHAIN_IDS = EXTRA_CONNECT_CHAINS.map(function (chain) {
  return chain.chainId;
});
export var ALL_SUPPORTED_RPC_URLS = ALL_SUPPORTED_CHAINS.reduce(function (prev, next) {
  return _objectSpread(_objectSpread({}, prev), {}, _defineProperty({}, next.chainId, next.rpcUrl));
}, {});
export var SupportedChainId;

(function (SupportedChainId) {
  SupportedChainId[SupportedChainId["Mainnet"] = 1] = "Mainnet";
  SupportedChainId[SupportedChainId["Goerli"] = 5] = "Goerli";
  SupportedChainId[SupportedChainId["ArbitrumOne"] = 42161] = "ArbitrumOne";
  SupportedChainId[SupportedChainId["ArbitrumRinkeby"] = 421611] = "ArbitrumRinkeby";
})(SupportedChainId || (SupportedChainId = {}));

export var ALL_SUPPORTED_WC_RPC_URLS = ALL_SUPPORTED_WC_CHAINS.reduce(function (prev, next) {
  return _objectSpread(_objectSpread({}, prev), {}, _defineProperty({}, next.chainId, next.rpcUrl));
}, {});
export var DEFAULT_RPC_PROVIDERS = (_DEFAULT_RPC_PROVIDER = {}, _defineProperty(_DEFAULT_RPC_PROVIDER, SupportedChainId.Mainnet, new StaticJsonRpcProvider(Mainnet.rpcUrl)), _defineProperty(_DEFAULT_RPC_PROVIDER, SupportedChainId.Goerli, new StaticJsonRpcProvider(Goerli.rpcUrl)), _defineProperty(_DEFAULT_RPC_PROVIDER, SupportedChainId.ArbitrumOne, new StaticJsonRpcProvider(ArbitrumOne.rpcUrl)), _defineProperty(_DEFAULT_RPC_PROVIDER, SupportedChainId.ArbitrumRinkeby, new StaticJsonRpcProvider(ArbitrumRinkeby.rpcUrl)), _DEFAULT_RPC_PROVIDER);