import { useState, useLayoutEffect } from 'react';
export var useIsOverflow = function useIsOverflow(ref) {
  var _useState = useState(undefined),
      isOverflow = _useState[0],
      setIsOverflow = _useState[1];

  useLayoutEffect(function () {
    var current = ref.current;

    var trigger = function trigger() {
      var hasOverflow = current.scrollWidth > current.clientWidth;
      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [ref.current]);
  return isOverflow;
};