import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _NETWORK_POLLING_INTE;

import { Web3Provider } from '@ethersproject/providers';
import { ArbitrumOne, ArbitrumRinkeby } from "../model/chain/ethereum";
export var NETWORK_POLLING_INTERVALS = (_NETWORK_POLLING_INTE = {}, _defineProperty(_NETWORK_POLLING_INTE, ArbitrumOne.chainId, 1000), _defineProperty(_NETWORK_POLLING_INTE, ArbitrumRinkeby.chainId, 1000), _NETWORK_POLLING_INTE);
export var getLibrary = function getLibrary(provider) {
  var chainIdOrAny = typeof provider.chainId === 'number' ? provider.chainId : typeof provider.chainId === 'string' ? parseInt(provider.chainId, 16) : 'any'; // @note: why use `any` https://github.com/ethers-io/ethers.js/issues/866

  var library = new Web3Provider(provider, chainIdOrAny);
  library.pollingInterval = 15000;
  library.detectNetwork().then(function (network) {
    var networkPollingInterval = NETWORK_POLLING_INTERVALS[network.chainId];

    if (networkPollingInterval) {
      console.debug('Setting polling interval', networkPollingInterval);
      library.pollingInterval = networkPollingInterval;
    }
  });
  return library;
};