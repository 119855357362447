import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from 'styled-components';
export var Box = styled.div.withConfig({
  displayName: "Box",
  componentId: "sc-1m36i0c-0"
})(["width:", ";max-width:", ";max-height:", ";min-height:", ";display:flex;padding:0;align-items:", ";justify-content:", ";padding:", ";border:", ";border-radius:", ";white-space:", ";overflow-wrap:", ";overflow:", ";cursor:", ";background-color:", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";"], function (_ref) {
  var width = _ref.width;
  return width !== null && width !== void 0 ? width : '100%';
}, function (_ref2) {
  var maxWidth = _ref2.maxWidth;
  return maxWidth !== null && maxWidth !== void 0 ? maxWidth : 'unset';
}, function (_ref3) {
  var maxHeight = _ref3.maxHeight;
  return maxHeight !== null && maxHeight !== void 0 ? maxHeight : 'unset';
}, function (_ref4) {
  var minHeight = _ref4.minHeight;
  return minHeight !== null && minHeight !== void 0 ? minHeight : 'unset';
}, function (_ref5) {
  var align = _ref5.align;
  return align !== null && align !== void 0 ? align : 'center';
}, function (_ref6) {
  var justify = _ref6.justify;
  return justify !== null && justify !== void 0 ? justify : 'flex-start';
}, function (_ref7) {
  var padding = _ref7.padding;
  return padding;
}, function (_ref8) {
  var border = _ref8.border;
  return border;
}, function (_ref9) {
  var borderRadius = _ref9.borderRadius;
  return borderRadius;
}, function (_ref10) {
  var whiteSpace = _ref10.whiteSpace;
  return whiteSpace !== null && whiteSpace !== void 0 ? whiteSpace : 'normal';
}, function (_ref11) {
  var overflowWrap = _ref11.overflowWrap;
  return overflowWrap !== null && overflowWrap !== void 0 ? overflowWrap : 'break-word';
}, function (_ref12) {
  var overflow = _ref12.overflow;
  return overflow !== null && overflow !== void 0 ? overflow : 'hidden';
}, function (_ref13) {
  var pointer = _ref13.pointer;
  return pointer ? 'pointer' : 'default';
}, function (_ref14) {
  var backgroundColor = _ref14.backgroundColor;
  return backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : 'transparent';
}, function (_ref15) {
  var mb = _ref15.mb;
  return mb && "margin-bottom: ".concat(mb);
}, function (_ref16) {
  var mt = _ref16.mt;
  return mt && "margin-top: ".concat(mt);
}, function (_ref17) {
  var ml = _ref17.ml;
  return ml && "margin-left: ".concat(ml);
}, function (_ref18) {
  var mr = _ref18.mr;
  return mr && "margin-right: ".concat(mr);
}, function (_ref19) {
  var mx = _ref19.mx;
  return mx && "margin-left: ".concat(mx, "; margin-right: ").concat(mx);
}, function (_ref20) {
  var my = _ref20.my;
  return my && "margin-top: ".concat(my, "; margin-bottom: ").concat(my);
}, function (_ref21) {
  var pb = _ref21.pb;
  return pb && "padding-bottom: ".concat(pb);
}, function (_ref22) {
  var pt = _ref22.pt;
  return pt && "padding-top: ".concat(pt);
}, function (_ref23) {
  var pl = _ref23.pl;
  return pl && "padding-left: ".concat(pl);
}, function (_ref24) {
  var pr = _ref24.pr;
  return pr && "padding-right: ".concat(pr);
}, function (_ref25) {
  var px = _ref25.px;
  return px && "padding-left: ".concat(px, "; padding-right: ").concat(px);
}, function (_ref26) {
  var py = _ref26.py;
  return py && "padding-top: ".concat(py, "; padding-bottom: ").concat(py);
});
export var ExchangeBox = styled.div.withConfig({
  displayName: "ExchangeBox",
  componentId: "sc-1m36i0c-1"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;margin:68px auto 12px auto;&.hide{display:none;}", " &.withChart{margin-bottom:0;}"], function (_ref27) {
  var theme = _ref27.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    margin: 20px auto;\n    padding: 0 15px;\n    \n    &.limitswap {\n      margin-bottom: 15px;\n    }\n  "])));
});