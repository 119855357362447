import { useWeb3React } from '@web3-react/core';
import { useBlockNumber } from "../application/hooks";
import { multicall } from "./instance";
// re-export for convenience
export { NEVER_RELOAD } from '@uniswap/redux-multicall'; // re-export for convenience

var _multicall$hooks = multicall.hooks,
    _useMultipleContractSingleData = _multicall$hooks.useMultipleContractSingleData,
    _useSingleCallResult = _multicall$hooks.useSingleCallResult,
    _useSingleContractMultipleData = _multicall$hooks.useSingleContractMultipleData,
    _useSingleContractWithCallData = _multicall$hooks.useSingleContractWithCallData; // Create wrappers for hooks so consumers don't need to get latest block themselves

export function useMultipleContractSingleData() {
  var _useCallContext = useCallContext(),
      chainId = _useCallContext.chainId,
      latestBlock = _useCallContext.latestBlock;

  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return _useMultipleContractSingleData.apply(void 0, [chainId, latestBlock].concat(args));
}
export function useSingleCallResult() {
  var _useCallContext2 = useCallContext(),
      chainId = _useCallContext2.chainId,
      latestBlock = _useCallContext2.latestBlock;

  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }

  return _useSingleCallResult.apply(void 0, [chainId, latestBlock].concat(args));
}
export function useSingleContractMultipleData() {
  var _useCallContext3 = useCallContext(),
      chainId = _useCallContext3.chainId,
      latestBlock = _useCallContext3.latestBlock;

  for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    args[_key3] = arguments[_key3];
  }

  return _useSingleContractMultipleData.apply(void 0, [chainId, latestBlock].concat(args));
}
export function useSingleContractWithCallData() {
  var _useCallContext4 = useCallContext(),
      chainId = _useCallContext4.chainId,
      latestBlock = _useCallContext4.latestBlock;

  for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
    args[_key4] = arguments[_key4];
  }

  return _useSingleContractWithCallData.apply(void 0, [chainId, latestBlock].concat(args));
}

function useCallContext() {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  var latestBlock = useBlockNumber();
  return {
    chainId: chainId,
    latestBlock: latestBlock
  };
}