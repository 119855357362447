import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_ROOT_DOMAIN } from "../constants/rootDomain";
export var useEvents = function useEvents() {
  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var locale = i18n.resolvedLanguage === 'zh' ? 'zh-CN' : 'en-US';
  var params = {
    platform: 'web',
    locale: locale
  };

  var fetcher = function fetcher(url) {
    return axios.get(url, {
      params: params
    }).then(function (res) {
      return res.data;
    });
  };

  var _useSWR = useSWR("https://api.".concat(API_ROOT_DOMAIN, "/v5/exchange/events"), fetcher),
      data = _useSWR.data,
      error = _useSWR.error;

  if (error) {
    console.error(error);
    return [];
  }

  if (!(data !== null && data !== void 0 && data.items)) return [];
  var events = data.items;
  return events;
};