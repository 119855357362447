import styled from 'styled-components';
export var Column = styled.div.withConfig({
  displayName: "Column",
  componentId: "sc-kjv21g-0"
})(["display:flex;flex-direction:column;justify-content:flex-start;"]);
export var ColumnCenter = styled(Column).withConfig({
  displayName: "ColumnCenter",
  componentId: "sc-kjv21g-1"
})(["width:100%;align-items:center;"]);
export var AutoColumn = styled.div.withConfig({
  displayName: "AutoColumn",
  componentId: "sc-kjv21g-2"
})(["display:grid;grid-auto-rows:auto;grid-row-gap:", ";justify-items:", ";"], function (_ref) {
  var gap = _ref.gap;
  return gap === 'sm' && '8px' || gap === 'md' && '12px' || gap === 'lg' && '24px' || gap;
}, function (_ref2) {
  var justify = _ref2.justify;
  return justify !== null && justify !== void 0 ? justify : 'flex-start';
});