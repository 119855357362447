import { useEffect } from 'react';
export var useClickAway = function useClickAway(ref, handler) {
  useEffect(function () {
    var callback = function callback(event) {
      var el = ref.current;
      if (!event || !el || el.contains(event.target)) return;
      handler(event);
    };

    if (typeof document === 'undefined') return;
    document.addEventListener('click', callback, true);
    return function () {
      return document.removeEventListener('click', callback, true);
    };
  }, [ref, handler]);
};
export default useClickAway;