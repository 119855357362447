import { useWeb3React } from '@web3-react/core';
import { ArbitrumOne, ArbitrumRinkeby } from "../model/chain/ethereum";
export var useNetworkValidator = function useNetworkValidator() {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  var isArbitrumOne = chainId === ArbitrumOne.chainId;
  var isArbitrumRinkeby = chainId === ArbitrumRinkeby.chainId;
  var isArbitrum = isArbitrumOne || isArbitrumRinkeby;
  return {
    isArbitrum: isArbitrum
  };
};