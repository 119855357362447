import _toConsumableArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from "../hooks";
import { ApplicationModal, setModalState, setOpenModal } from "./reducer";
export var useBlockNumber = function useBlockNumber() {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  return useAppSelector(function (state) {
    return state.application.blockNumber[chainId];
  });
};
export var useMainnetBlockNumber = function useMainnetBlockNumber() {
  return useAppSelector(function (state) {
    return state.application.blockNumber[1];
  });
};
export var useModalOpened = function useModalOpened(modal) {
  var modalOpened = useAppSelector(function (state) {
    return state.application.openModal;
  });
  return modalOpened.includes(modal);
};
export var useOneOfModalOpened = function useOneOfModalOpened(modals) {
  var modalOpened = useAppSelector(function (state) {
    return state.application.openModal;
  });
  return modalOpened.some(function (modal) {
    return modals.includes(modal);
  });
};
export var useModalToggle = function useModalToggle(modal, opt) {
  var _ref = opt || {
    stack: false,
    modalState: null
  },
      stack = _ref.stack,
      modalState = _ref.modalState;

  var dispatch = useAppDispatch();
  var opened = useModalOpened(modal);
  var modalOpened = useAppSelector(function (state) {
    return state.application.openModal;
  });
  return useCallback(function () {
    dispatch(setOpenModal(opened ? modalOpened.filter(function (m) {
      return m !== modal;
    }) : stack ? [].concat(_toConsumableArray(modalOpened), [modal]) : [modal]));
    dispatch(setModalState({
      modal: modal,
      state: modalState
    }));
  }, [dispatch, modal, opened, modalState, stack, modalOpened]);
};
export var useModalSwitch = function useModalSwitch(modal) {
  var dispatch = useAppDispatch();
  var opened = useModalOpened(modal);
  var modalOpened = useAppSelector(function (state) {
    return state.application.openModal;
  });
  return useCallback(function (open, opt) {
    // opened
    if (open === opened) return;

    var _ref2 = opt || {
      stack: false,
      modalState: null
    },
        stack = _ref2.stack,
        modalState = _ref2.modalState;

    if (stack) {
      dispatch(setOpenModal(open ? [].concat(_toConsumableArray(modalOpened), [modal]) : modalOpened.filter(function (m) {
        return m !== modal;
      })));
    } else {
      dispatch(setOpenModal(open ? [modal] : []));
    }

    dispatch(setModalState({
      modal: modal,
      state: modalState
    }));
  }, [dispatch, modal, opened]);
};
export var useModalState = function useModalState(modal) {
  return useAppSelector(function (state) {
    var _state$application$mo;

    return (_state$application$mo = state.application.modalState[modal]) !== null && _state$application$mo !== void 0 ? _state$application$mo : {};
  });
};
export var useApprovalModalStateDispatch = function useApprovalModalStateDispatch() {
  var dispatch = useAppDispatch();
  return useCallback(function (_ref3) {
    var progress = _ref3.progress,
        hash = _ref3.hash;
    dispatch(setModalState({
      modal: ApplicationModal.APPROVAL_STATUS,
      state: {
        progress: progress,
        hash: hash
      }
    }));
  }, [dispatch]);
};
export var useRefreshToken = function useRefreshToken() {
  return useAppSelector(function (state) {
    return state.application.refreshToken;
  });
};
export var useFeeData = function useFeeData() {
  var _useWeb3React2 = useWeb3React(),
      chainId = _useWeb3React2.chainId;

  return useAppSelector(function (state) {
    return state.application.feeData[chainId];
  });
};