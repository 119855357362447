import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { ethers } from 'ethers';
import { useMemo } from 'react';
import { ERC20Interface } from "../constants/abi";
import { FixedPointNumber } from "../utils/fixedPoint";
import { useMultipleContractSingleData, useSingleCallResult } from "../state/multicall/hooks";
import { useTokenContract } from "./useContract";
import { useWeb3React } from '@web3-react/core';
import { getBlocksPerFetchForChainId } from "../utils/getBlocksPerFetchForChainId";
export var useTokenAllowance = function useTokenAllowance(token, owner, spender) {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  var contract = useTokenContract(token === null || token === void 0 ? void 0 : token.address);
  var inputs = useMemo(function () {
    return [owner, spender];
  }, [owner, spender]);
  var blocksPerFetch = useMemo(function () {
    return getBlocksPerFetchForChainId(chainId);
  }, [chainId]);
  var allowance = useSingleCallResult(contract, 'allowance', inputs, {
    blocksPerFetch: blocksPerFetch
  }).result;
  return useMemo(function () {
    return token && allowance ? // ? fromRawToCurrencyAmount(token, allowance.toString())
    new FixedPointNumber(allowance.toString(), token.decimals) : undefined;
  }, [token, allowance]);
};
export var useTokensAllowance = function useTokensAllowance(tokens, owner, spender) {
  var tokenAddresses = (tokens || []).map(function (_ref) {
    var address = _ref.address;
    return ethers.utils.getAddress(address);
  });
  var results = useMultipleContractSingleData(tokenAddresses, ERC20Interface, 'allowance', [owner, spender]);
  var anyLoading = useMemo(function () {
    return results.some(function (callState) {
      return callState.loading;
    });
  }, [results]);
  return useMemo(function () {
    return anyLoading ? false : results.reduce(function (acc, callState, i) {
      var _callState$result;

      var value = (_callState$result = callState.result) === null || _callState$result === void 0 ? void 0 : _callState$result[0];
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, tokens[i].address, {
        value: value,
        loading: callState.loading
      }));
    }, {});
  }, [tokens, anyLoading, results]);
};