import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import { MaxUint256, AddressZero } from '@ethersproject/constants';
import { useTranslation } from 'react-i18next';
import { useApproveCallback } from "../../hooks/useApproval";
import { useTokensAllowance } from "../../hooks/useTokenAllowance";
import { useModalOpened, useModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { useAllowanceTarget, useLimitSwapTokens, useSpotSwapTokens } from "../../state/dex/hooks";
import { Loading } from "../Loading";
import Modal from "../Modal";
import Switch from "../Switch";
import TokenLogo from "../TokenLogo";
import { StyledAllowanceManageItemWrapper, StyledManageItemRight, StyledManageList } from "./styled";
import { BigNumber } from 'ethers';
import { ApprovalProgress } from "../ApprovalModal/ApproveStatusLabel";
import { useGA, useUpdaterChainId } from "../../hooks";
import isEmpty from 'lodash/isEmpty';
export var AllowanceListModal = function AllowanceListModal() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var toggleAllowanceListModal = useModalToggle(ApplicationModal.ALLOWANCE_LIST);
  var isAllowanceListModalOpen = useModalOpened(ApplicationModal.ALLOWANCE_LIST);
  return __jsx(Modal, {
    title: t('allowance_manage'),
    isOpen: isAllowanceListModalOpen,
    onDismiss: toggleAllowanceListModal,
    animate: false
  }, __jsx(AllowanceList, null));
};

var AllowanceList = function AllowanceList() {
  var expectChainId = useUpdaterChainId();
  var spotSwapTokens = useSpotSwapTokens(expectChainId);
  var limitSwapTokens = useLimitSwapTokens(expectChainId);
  var tokens = useMemo(function () {
    return !isEmpty(limitSwapTokens) ? limitSwapTokens : spotSwapTokens;
  }, [spotSwapTokens, limitSwapTokens]);

  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account;

  var allowanceTarget = useAllowanceTarget();
  var allowanceResults = useTokensAllowance(Object.values(tokens), account, allowanceTarget);

  if (!allowanceResults) {
    return __jsx(Loading, null);
  } // 排序规则：如无任何授权，则按名称 a-z 排序。若有授权代币，则授权代币在最前，按名称 a-z 排序。


  return __jsx(StyledManageList, null, Object.values(tokens).filter(function (token) {
    return token.address !== AddressZero;
  }).sort(function (t1, t2) {
    var s1 = t1.symbol.toUpperCase();
    var s2 = t2.symbol.toUpperCase();
    return s1 > s2 ? 1 : s1 === s2 ? 0 : -1;
  }).sort(function (t1, t2) {
    var _a1$value, _a2$value;

    var a1 = allowanceResults[t1.address];
    var a2 = allowanceResults[t2.address];
    var a1HaveAuthorized = !(a1 !== null && a1 !== void 0 && (_a1$value = a1.value) !== null && _a1$value !== void 0 && _a1$value.isZero());
    var a2HaveAuthorized = !(a2 !== null && a2 !== void 0 && (_a2$value = a2.value) !== null && _a2$value !== void 0 && _a2$value.isZero());
    if (a1HaveAuthorized && !a2HaveAuthorized) return -1;else if (!a1HaveAuthorized && a2HaveAuthorized) return 1;else return 0;
  }).map(function (token) {
    return __jsx(AllowanceListItem, {
      key: token.address,
      token: token,
      allowance: allowanceResults[token.address]
    });
  }));
};

var AllowanceListItem = function AllowanceListItem(_ref) {
  var token = _ref.token,
      _ref$allowance = _ref.allowance,
      allowance = _ref$allowance === void 0 ? {
    loading: false,
    value: undefined
  } : _ref$allowance;
  var logo = token.logo,
      symbol = token.symbol;
  var loading = allowance.loading,
      allowanceValue = allowance.value;
  var checked = useMemo(function () {
    return allowanceValue && !allowanceValue.isZero();
  }, [allowanceValue]);

  var _useGA = useGA(),
      trackClick = _useGA.trackClick;

  var allowanceTarget = useAllowanceTarget();

  var _useApproveCallback = useApproveCallback(token, checked ? BigNumber.from(0) : MaxUint256, allowanceTarget),
      _useApproveCallback2 = _slicedToArray(_useApproveCallback, 2),
      approvalState = _useApproveCallback2[0],
      approve = _useApproveCallback2[1];

  var modalState = useMemo(function () {
    return {
      token: token,
      approvalState: approvalState,
      progress: checked ? ApprovalProgress.CANCEL_APPROVING : ApprovalProgress.APPROVING
    };
  }, [approvalState, token]);
  var toggleApprovalModal = useModalToggle(ApplicationModal.APPROVAL_STATUS, {
    stack: true,
    modalState: modalState
  });

  var triggerApprove = function triggerApprove() {
    toggleApprovalModal();
    approve().then(function () {
      trackClick('webtl_mywallet_appove_confirm', {
        is_success: true,
        symbol: token.symbol,
        token: token.address
      });
    })["catch"](function (error) {
      trackClick('webtl_mywallet_appove_confirm', {
        is_success: false,
        symbol: token.symbol,
        token: token.address,
        message: error === null || error === void 0 ? void 0 : error.message
      });
    });
  };

  return __jsx(StyledAllowanceManageItemWrapper, {
    className: "allowance-manage-item"
  }, __jsx("div", {
    className: "manage-item-left"
  }, __jsx(TokenLogo, {
    logo: logo,
    symbol: symbol,
    size: 50
  })), __jsx(StyledManageItemRight, {
    className: "manage-item-right"
  }, __jsx("span", {
    className: "manage-item-symbol"
  }, symbol), __jsx("div", {
    className: "manage-item-switch"
  }, __jsx(Switch, {
    checked: loading ? !checked : checked,
    loading: loading,
    onChange: triggerApprove
  }))));
};