import { useCallback, useEffect, useState } from 'react';
import { isClient } from "../utils/userAgent";
var VISIBILITY_STATE_SUPPORTED = isClient && 'visibilityState' in document;

function isWindowVisible() {
  return !VISIBILITY_STATE_SUPPORTED || document.visibilityState !== 'hidden';
}
/**
 * Returns whether the window is currently visible to the user.
 */


export var useIsWindowVisible = function useIsWindowVisible() {
  var _useState = useState(isWindowVisible()),
      focused = _useState[0],
      setFocused = _useState[1];

  var listener = useCallback(function () {
    setFocused(isWindowVisible());
  }, [setFocused]);
  useEffect(function () {
    if (!VISIBILITY_STATE_SUPPORTED) return undefined;
    document.addEventListener('visibilitychange', listener);
    return function () {
      document.removeEventListener('visibilitychange', listener);
    };
  }, [listener]);
  return focused;
};