import { createAction } from '@reduxjs/toolkit';
export var addSpotTokens = createAction('dex/addSpotTokens');
export var addLimitTokens = createAction('dex/addLimitTokens');
export var addCustomSpotTokens = createAction('dex/addCustomSpotTokens');
export var addCustomLimitTokens = createAction('dex/addCustomLimitTokens');
export var updateConfig = createAction('dex/updateConfig');
export var updateLimitSwapAmount = createAction('dex/updateLimitSwapAmount');
export var updateSpotSwapAmount = createAction('dex/updateSpotSwapAmount');
export var updateSpotSwapPairAmount = createAction('dex/updateSpotSwapPairAmount');
export var updateLimitSwapPairAmount = createAction('dex/updateLimitSwapPairAmount');
export var updateSpotSwapPairToken = createAction('dex/updateSpotSwapPairToken');
export var updateLimitSwapPairToken = createAction('dex/updateLimitSwapPairToken');
export var updateSpotSwapPair = createAction('dex/updateSpotSwapPair');
export var updateLimitSwapPair = createAction('dex/updateLimitSwapPair');
export var updateLimitOrderSubmitStatus = createAction('dex/updateLimitOrderSubmitStatus');
export var updateLimitOrderUserConfig = createAction('dex/updateLimitOrderUserConfig');
export var updateSpotSwapUserConfig = createAction('dex/updateSpotSwapUserConfig');
export var updateTokensPrice = createAction('dex/updateTokensPrice');
export var updateTokensPriceList = createAction('dex/updateTokensPriceList');
export var removeTokensPriceList = createAction('dex/removeTokensPriceList');