import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import useSWR from 'swr';
import { axios } from "../apis/common";
import { API_ROOT_DOMAIN } from "../constants/rootDomain";
import { ArbitrumOne, ArbitrumRinkeby, Goerli, Mainnet } from "../model/chain/ethereum";
import { useRefreshToken } from "../state/application/hooks";
export var LimitOrderStatus; // @TODO use sdk type define

(function (LimitOrderStatus) {
  LimitOrderStatus["Pending"] = "pending";
  LimitOrderStatus["PartialFilled"] = "partialfilled";
  LimitOrderStatus["Fulfilled"] = "fulfilled";
  LimitOrderStatus["Cancelled"] = "cancelled";
  LimitOrderStatus["Expired"] = "expired";
})(LimitOrderStatus || (LimitOrderStatus = {}));

export var useUserLimitOrderList = function useUserLimitOrderList(status) {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId,
      account = _useWeb3React.account;

  var refreshToken = useRefreshToken();

  var _useSWR = useSWR([chainId, status, account, refreshToken], function () {
    if (account && [ArbitrumOne.chainId, ArbitrumRinkeby.chainId, Goerli.chainId, Mainnet.chainId].includes(chainId)) {
      return axios.get("https://api.".concat(API_ROOT_DOMAIN, "/v5/limit-order/limitorder"), {
        params: {
          status: status.join(','),
          maker: account,
          chainId: chainId
        }
      });
    }

    return Promise.resolve([]);
  }, {
    refreshInterval: 15000
  }),
      data = _useSWR.data,
      error = _useSWR.error;

  if (data) {
    data.forEach(function (item) {
      item.progress = '0';
      item.remainInputAmountToDeal = item.makerTokenAmount;

      if (item.trades && item.trades.length > 0) {
        var filledTrades = item.trades.filter(function (t) {
          return t.status === 'success';
        });
        var filledTakerAmount = filledTrades.reduce(function (prev, curr) {
          return prev.plus(curr.takerTokenFilledAmount || '0');
        }, new BigNumber(0));
        var filledMakerAmount = filledTrades.reduce(function (prev, curr) {
          return prev.plus(curr.makerTokenFilledAmount || '0');
        }, new BigNumber(0));
        var progress = filledTakerAmount.multipliedBy(100).dividedBy(item.takerTokenAmount).toFormat(2);
        item.progress = progress;
        item.remainInputAmountToDeal = new BigNumber(item.makerTokenAmount).minus(filledMakerAmount).toString();
      }
    });
  }

  return {
    data: data,
    error: error
  };
};