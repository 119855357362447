import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { getAddress } from 'ethers/lib/utils';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TradeDirection } from "../../components/Exchange/types";
import { ENV, RUNTIME_ENV } from "../../constants/env";
import { addCustomLimitTokens, addCustomSpotTokens, addLimitTokens, addSpotTokens, removeTokensPriceList, updateConfig, updateLimitOrderSubmitStatus, updateLimitOrderUserConfig, updateSpotSwapUserConfig, updateLimitSwapAmount, updateLimitSwapPair, updateLimitSwapPairAmount, updateLimitSwapPairToken, updateSpotSwapAmount, updateSpotSwapPair, updateSpotSwapPairAmount, updateSpotSwapPairToken, updateTokensPrice, updateTokensPriceList } from "./actions";
import { LimitOrderExpiration, LimitOrderSubmitStatus } from "./type";
export var initialState = {
  spotTokens: {},
  limitTokens: {},
  userCustomSpotTokens: {},
  userCustomLimitTokens: {},
  exchangePair: [null, null],
  spotSwapPair: [null, null],
  limitSwapPair: [null, null],
  limitSwapInputAmount: '1.0',
  limitSwapOutputAmount: '0',
  spotSwapInputAmount: '1.0',
  spotSwapOutputAmount: '0',
  prices: {},
  config: {},
  limitOrderSubmitStatus: LimitOrderSubmitStatus.Idle,
  limitOrderUserConfig: {
    expiration: LimitOrderExpiration.Day7,
    isInputRateDirection: true
  },
  spotSwapUserConfig: {
    sendByRelayer: false
  }
};
export var dexReducer = createReducer(initialState, function (builder) {
  return builder.addCase(addSpotTokens, function (state, _ref) {
    var _ref$payload = _ref.payload,
        tokens = _ref$payload.tokens,
        chainId = _ref$payload.chainId;
    if (!state.spotTokens) state.spotTokens = {};
    state.spotTokens[chainId] = state.spotTokens[chainId] || {};
    tokens === null || tokens === void 0 ? void 0 : tokens.forEach(function (token) {
      state.spotTokens[chainId][token.address] = token;
    });
  }).addCase(addCustomSpotTokens, function (state, _ref2) {
    var _ref2$payload = _ref2.payload,
        tokens = _ref2$payload.tokens,
        chainId = _ref2$payload.chainId;
    if (!state.userCustomSpotTokens) state.userCustomSpotTokens = {};
    state.userCustomSpotTokens[chainId] = state.userCustomSpotTokens[chainId] || {};
    tokens === null || tokens === void 0 ? void 0 : tokens.forEach(function (token) {
      state.userCustomSpotTokens[chainId][token.address] = token;
    });
  }).addCase(addLimitTokens, function (state, _ref3) {
    var _ref3$payload = _ref3.payload,
        tokens = _ref3$payload.tokens,
        chainId = _ref3$payload.chainId;
    if (!state.limitTokens) state.limitTokens = {};
    state.limitTokens[chainId] = state.limitTokens[chainId] || {};
    tokens === null || tokens === void 0 ? void 0 : tokens.forEach(function (token) {
      state.limitTokens[chainId][token.address] = token;
    });
  }).addCase(addCustomLimitTokens, function (state, _ref4) {
    var _ref4$payload = _ref4.payload,
        tokens = _ref4$payload.tokens,
        chainId = _ref4$payload.chainId;
    if (!state.userCustomLimitTokens) state.userCustomLimitTokens = {};
    state.userCustomLimitTokens[chainId] = state.userCustomLimitTokens[chainId] || {};
    tokens === null || tokens === void 0 ? void 0 : tokens.forEach(function (token) {
      state.userCustomLimitTokens[chainId][token.address] = token;
    });
  }).addCase(updateSpotSwapPairToken, function (state, _ref5) {
    var _ref5$payload = _ref5.payload,
        direction = _ref5$payload.direction,
        token = _ref5$payload.token;
    if (!state.spotSwapPair) state.spotSwapPair = [];
    if (direction === TradeDirection.Input) state.spotSwapPair[0] = token;
    if (direction === TradeDirection.Output) state.spotSwapPair[1] = token;
  }).addCase(updateSpotSwapPair, function (state, _ref6) {
    var _ref6$payload = _ref6.payload,
        inputToken = _ref6$payload.inputToken,
        outputToken = _ref6$payload.outputToken;
    state.spotSwapPair = [inputToken, outputToken];
  }).addCase(updateLimitSwapPairToken, function (state, _ref7) {
    var _ref7$payload = _ref7.payload,
        direction = _ref7$payload.direction,
        token = _ref7$payload.token;
    if (!state.limitSwapPair) state.limitSwapPair = [];
    if (direction === TradeDirection.Input) state.limitSwapPair[0] = token;
    if (direction === TradeDirection.Output) state.limitSwapPair[1] = token;
  }).addCase(updateLimitSwapAmount, function (state, _ref8) {
    var _ref8$payload = _ref8.payload,
        direction = _ref8$payload.direction,
        amount = _ref8$payload.amount;
    if (direction === TradeDirection.Input) state.limitSwapInputAmount = amount;
    if (direction === TradeDirection.Output) state.limitSwapOutputAmount = amount;
  }).addCase(updateSpotSwapAmount, function (state, _ref9) {
    var _ref9$payload = _ref9.payload,
        direction = _ref9$payload.direction,
        amount = _ref9$payload.amount;
    if (direction === TradeDirection.Input) state.spotSwapInputAmount = amount;
    if (direction === TradeDirection.Output) state.spotSwapOutputAmount = amount;
  }).addCase(updateSpotSwapPairAmount, function (state, _ref10) {
    var _ref10$payload = _ref10.payload,
        inputAmount = _ref10$payload.inputAmount,
        outputAmount = _ref10$payload.outputAmount;
    state.spotSwapInputAmount = inputAmount;
    state.spotSwapOutputAmount = outputAmount;
  }).addCase(updateLimitSwapPairAmount, function (state, _ref11) {
    var _ref11$payload = _ref11.payload,
        inputAmount = _ref11$payload.inputAmount,
        outputAmount = _ref11$payload.outputAmount;
    state.limitSwapInputAmount = inputAmount;
    state.limitSwapOutputAmount = outputAmount;
  }).addCase(updateLimitSwapPair, function (state, _ref12) {
    var _ref12$payload = _ref12.payload,
        inputToken = _ref12$payload.inputToken,
        outputToken = _ref12$payload.outputToken;
    state.limitSwapPair = [inputToken, outputToken];
  }).addCase(updateConfig, function (state, _ref13) {
    var _ref13$payload = _ref13.payload,
        chainId = _ref13$payload.chainId,
        config = _ref13$payload.config;
    if (!state.config) state.config = {};
    state.config[chainId] = config;
  }).addCase(updateLimitOrderSubmitStatus, function (state, _ref14) {
    var payload = _ref14.payload;
    state.limitOrderSubmitStatus = payload;
  }).addCase(updateLimitOrderUserConfig, function (state, _ref15) {
    var payload = _ref15.payload;
    if (!state.limitOrderUserConfig) state.limitOrderUserConfig = {};
    Object.keys(payload).forEach(function (key) {
      state.limitOrderUserConfig[key] = payload[key];
    });
  }).addCase(updateSpotSwapUserConfig, function (state, _ref16) {
    var payload = _ref16.payload;
    if (!state.spotSwapUserConfig) state.spotSwapUserConfig = {};
    Object.keys(payload).forEach(function (key) {
      state.spotSwapUserConfig[key] = payload[key];
    });
  }).addCase(updateTokensPrice, function (state, _ref17) {
    var _ref17$payload = _ref17.payload,
        marketPrices = _ref17$payload.marketPrices,
        chainId = _ref17$payload.chainId,
        blockNumber = _ref17$payload.blockNumber,
        currency = _ref17$payload.currency;
    if (!state.prices) state.prices = {};
    if (!state.prices[chainId]) state.prices[chainId] = {};
    marketPrices.filter(function (p) {
      return Boolean(p.address);
    }).forEach(function (p) {
      if (!state.prices[chainId][getAddress(p.address)]) state.prices[chainId][getAddress(p.address)] = {};
      var price = p.price || 0;

      if (!price && (ENV.development || ENV.local)) {
        price = Number(p.address) % 100 + new Date().getMinutes();
      }

      state.prices[chainId][getAddress(p.address)][currency] = {
        price: String(price),
        lastUpdateBlockNumber: blockNumber
      };
    });
  }).addCase(updateTokensPriceList, function (state, _ref18) {
    var _ref18$payload = _ref18.payload,
        chainId = _ref18$payload.chainId,
        addresses = _ref18$payload.addresses,
        currency = _ref18$payload.currency;
    if (!state.prices) state.prices = [];
    if (!state.prices[chainId]) state.prices[chainId] = {};
    addresses.forEach(function (addr) {
      var address = getAddress(addr);
      if (state.prices[chainId][address]) return;
      state.prices[chainId][address] = _defineProperty({}, currency, {});
    });
  }).addCase(removeTokensPriceList, function (state, _ref19) {
    var _ref19$payload = _ref19.payload,
        addresses = _ref19$payload.addresses,
        chainId = _ref19$payload.chainId,
        currency = _ref19$payload.currency;
    if (!state.prices) return;
    if (!state.prices[chainId]) return;
    addresses.forEach(function (addr) {
      var address = getAddress(addr);

      if (state.prices[chainId][address]) {
        delete state.prices[chainId][address];
      }
    });
  }).addMatcher(isAnyOf(updateLimitSwapPair, updateLimitSwapPairToken), function (state) {
    state.limitSwapInputAmount = initialState.limitSwapInputAmount;
    state.limitSwapOutputAmount = initialState.limitSwapOutputAmount;
  }).addMatcher(isAnyOf(updateSpotSwapPair, updateSpotSwapPairToken), function (state) {
    state.spotSwapInputAmount = initialState.spotSwapInputAmount;
    state.spotSwapOutputAmount = initialState.spotSwapOutputAmount;
  });
});
export var dexPersistConfig = {
  key: "sub-dex-".concat(RUNTIME_ENV),
  storage: storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: ['spotSwapInputAmount', 'spotSwapOutputAmount', 'limitSwapInputAmount', 'limitSwapOutputAmount', 'limitOrderSubmitStatus', 'limitOrderUserConfig']
};
export default persistReducer(dexPersistConfig, dexReducer);