import _toConsumableArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { ConnectionType } from "./../connection/type";
import { useMemo } from 'react';
import { useSelectedConnectionType } from "../state/user/hooks";
import { getConnection } from "../connection/utils";
var MANUAL_CONNECT_WALLETS = [ConnectionType.Injected, ConnectionType.ImTokenWalletConnect, ConnectionType.ImTokenCallApp, ConnectionType.UnstoppableDomain, ConnectionType.Coinbase, ConnectionType.WalletConnectV2, ConnectionType.ImKey];
export var useOrderedConnections = function useOrderedConnections() {
  var selectedConnectionType = useSelectedConnectionType();
  return useMemo(function () {
    var orderedConnectionTypes = []; // 优先自动连接 Gnosis Safe

    orderedConnectionTypes.push(ConnectionType.GnosisSafe); // 上次连接过钱包

    if (selectedConnectionType) {
      orderedConnectionTypes.push(selectedConnectionType);
    }

    orderedConnectionTypes.push.apply(orderedConnectionTypes, _toConsumableArray(MANUAL_CONNECT_WALLETS.filter(function (type) {
      return type !== selectedConnectionType;
    }))); // fallback to network

    orderedConnectionTypes.push(ConnectionType.Network);
    return orderedConnectionTypes.map(getConnection).filter(Boolean);
  }, [selectedConnectionType]);
};