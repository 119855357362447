import React from "react";
var __jsx = React.createElement;
import { Web3ReactProvider, useWeb3React } from '@web3-react/core';
import { useEffect, useMemo } from 'react';
import { DEFAULT_RPC_PROVIDERS } from "../../constants/chain";
import { useEagerlyConnect } from "../../hooks/useEagerlyConnect";
import { useOrderedConnections } from "../../hooks/useOrderedConnections";
import { isSupportedChain } from "../../utils/chain";
export var Web3Provider = function Web3Provider(_ref) {
  var children = _ref.children;
  useEagerlyConnect();
  var connections = useOrderedConnections();
  console.log({
    connections: connections
  });
  var connectors = connections.map(function (connection) {
    return [connection.connector, connection.hooks];
  });
  var key = useMemo(function () {
    return connections.map(function (connection) {
      return connection.name;
    }).join('-');
  }, [connections]);
  return __jsx(Web3ReactProvider, {
    connectors: connectors,
    key: key
  }, __jsx(Updater, null), children);
};

var Updater = function Updater() {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId,
      provider = _useWeb3React.provider;

  var networkProvider = isSupportedChain(chainId) ? DEFAULT_RPC_PROVIDERS[chainId] : undefined;
  useEffect(function () {
    provider === null || provider === void 0 ? void 0 : provider.on('debug', trace);

    if (provider !== networkProvider) {
      networkProvider === null || networkProvider === void 0 ? void 0 : networkProvider.on('debug', trace);
    }

    return function () {
      provider === null || provider === void 0 ? void 0 : provider.off('debug', trace);
      networkProvider === null || networkProvider === void 0 ? void 0 : networkProvider.off('debug', trace);
    };
  }, [networkProvider, provider]);
  return null;
};

var USE_TRACE = process.env.USE_TRACE;

var trace = function trace(event) {
  if (!USE_TRACE) return;
  if (!event.request || !event.response) return;
  var _event$request = event.request,
      method = _event$request.method,
      params = _event$request.params,
      id = _event$request.id;
  console.groupCollapsed(method, id);
  console.debug(params, event.response);
  console.groupEnd();
};