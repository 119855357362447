import { useState } from 'react';
export var useHover = function useHover() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$mouseEnterDelayM = _ref.mouseEnterDelayMS,
      mouseEnterDelayMS = _ref$mouseEnterDelayM === void 0 ? 0 : _ref$mouseEnterDelayM,
      _ref$mouseLeaveDelayM = _ref.mouseLeaveDelayMS,
      mouseLeaveDelayMS = _ref$mouseLeaveDelayM === void 0 ? 0 : _ref$mouseLeaveDelayM;

  var _useState = useState(false),
      isHovering = _useState[0],
      setIsHovering = _useState[1];

  var mouseEnterTimer;
  var mouseOutTimer;
  return [isHovering, {
    onMouseEnter: function onMouseEnter() {
      clearTimeout(mouseOutTimer);
      mouseEnterTimer = setTimeout(function () {
        return setIsHovering(true);
      }, mouseEnterDelayMS);
    },
    onMouseLeave: function onMouseLeave() {
      clearTimeout(mouseEnterTimer);
      mouseOutTimer = setTimeout(function () {
        return setIsHovering(false);
      }, mouseLeaveDelayMS);
    }
  }];
};