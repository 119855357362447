import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useModalOpened, useModalSwitch, useModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import Modal from "../Modal";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-right-gray.svg";
import { ReactComponent as WrapETHIcon } from "../../assets/images/wrap-eth.svg";
import { Box, Column, Row } from "../Layout";
import { useGA } from "../../hooks";
export var ToolModal = function ToolModal() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var toggle = useModalToggle(ApplicationModal.TOOL);
  var switchWrap = useModalSwitch(ApplicationModal.WETH_WRAP);
  var isOpen = useModalOpened(ApplicationModal.TOOL);
  return __jsx(React.Fragment, null, __jsx(Modal, {
    title: t('tool'),
    isOpen: isOpen,
    onDismiss: toggle,
    animate: false
  }, __jsx(Box, {
    maxHeight: "70vh",
    minHeight: "400px",
    width: "100%",
    align: "flex-start"
  }, __jsx(ToolItem, {
    icon: __jsx(WrapETHIcon, null),
    title: t('wrap_eth'),
    desc: t('wrap_eth_desc'),
    onClick: function onClick() {
      return switchWrap(true);
    }
  }))));
};
var StyledTool = styled.div.withConfig({
  displayName: "StyledTool",
  componentId: "sc-10uf4oz-0"
})(["border:1px solid #eff0fa;border-radius:48px;display:flex;align-items:center;padding:15px;width:100%;cursor:pointer;&:hover{background-color:rgba(239,240,250,0.4);}p{margin:0;}.title{color:#000000;font-weight:500;font-size:15px;}.desc{color:#9598ab;font-weight:400;font-size:13px;}"]);
var StyledToolArrow = styled(ArrowIcon).withConfig({
  displayName: "StyledToolArrow",
  componentId: "sc-10uf4oz-1"
})(["margin-left:auto;"]);

var ToolItem = function ToolItem(_ref) {
  var icon = _ref.icon,
      title = _ref.title,
      desc = _ref.desc,
      _onClick = _ref.onClick;

  var _useGA = useGA(),
      trackElClickCurring = _useGA.trackElClickCurring;

  return __jsx(StyledTool, {
    "data-trackid": "webtl_instant_tool_detail",
    "data-trackdata": JSON.stringify({
      title: title
    }),
    onClick: function onClick(e) {
      return trackElClickCurring(e)(_onClick);
    }
  }, __jsx(Row, null, __jsx(Box, {
    width: "auto",
    mr: "15px"
  }, icon), __jsx(Column, null, __jsx("div", {
    className: "title"
  }, title), __jsx("p", {
    className: "desc"
  }, desc))), __jsx(StyledToolArrow, null));
};