export var INPUT_MODE;

(function (INPUT_MODE) {
  INPUT_MODE["MAKER"] = "maker";
  INPUT_MODE["TAKER"] = "taker";
})(INPUT_MODE || (INPUT_MODE = {}));

export var TradeDirection;

(function (TradeDirection) {
  TradeDirection["Input"] = "input";
  TradeDirection["Output"] = "output";
})(TradeDirection || (TradeDirection = {}));

export var RateState;

(function (RateState) {
  RateState["IDLE"] = "IDLE";
  RateState["LOADING"] = "LOADING";
  RateState["POLLING"] = "POLLING";
})(RateState || (RateState = {}));

export var TradeableState;

(function (TradeableState) {
  TradeableState["OK"] = "OK";
  TradeableState["NetworkReason"] = "NetworkReason";
  TradeableState["QuoteReason"] = "QuoteReason";
  TradeableState["NonEthNetworkReason"] = "NonEthNetworkReason";
  TradeableState["IDLE"] = "IDLE";
})(TradeableState || (TradeableState = {}));

export var PROTOCOL_TYPE;

(function (PROTOCOL_TYPE) {
  PROTOCOL_TYPE["AMM"] = "AMM";
  PROTOCOL_TYPE["PMM"] = "PMM";
})(PROTOCOL_TYPE || (PROTOCOL_TYPE = {}));