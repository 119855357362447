import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { RUNTIME_ENV } from "./../constants/env";
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import application from "./application/reducer";
import transactions from "./transactions/reducer";
import multicall from "./multicall/instance";
import user from "./user/reducer";
import dex from "./dex/reducer";
var persistConfig = {
  version: 1,
  key: "tokenlon-".concat(RUNTIME_ENV),
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['dex', 'application']
};
var rootReducer = combineReducers({
  application: application,
  multicall: multicall,
  dex: dex,
  transactions: transactions,
  user: user
});
var persistedReducer = persistReducer(persistConfig, rootReducer);
var store = configureStore({
  reducer: persistedReducer,
  middleware: function middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    });
  }
});
export default store;
export var persistor = persistStore(store);